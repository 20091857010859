  // 進場動畫
  // var percent=0;
  // var timer=setInterval(function(){
  //   $(".bar").css("width",percent+"%")
  //   percent+=1
  //   if(percent>100){
  //     clearInterval(timer)
  //     $(".pageloading").addClass("compelet")
  //   }
  // },30);

  // $('.scrollLink').bind('click', function(e) {
  //     e.preventDefault();
  //     var target = $(this).attr("href");
  //     $('html, body').stop().animate({ scrollTop: $(target).offset().top}, 1000, function() {
  //       location.hash = target + 300;
  //     });
      
  //     return false;
  // });

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

// function scrollAn(){
//   var reveals = (".pro-introImg");
//   var position = $(window).scrollTop();
//   var elementVisible = 100;

//   $(window).scroll(function() {
//     reveals.each(function() {
//       if(position > 0 && position < elementVisible){
//         $(this).addClass("animate__animated", "animate__pulse", "animate__slower", "animate__infinite")
//       }else{
//         $(this).removeClass("animate__animated", "animate__pulse", "animate__slower", "animate__infinite")
//       }
//     });
//   })
// }


$(function() {

  // $(window).scroll(function() {
  //   var reveals = $(".pro-introImg");
  //   var scrollTop = $(window).scrollTop();
  //   var elementVisible = 150;

  //   reveals.each(function(){
  //     if (elementVisible < scrollTop - reveals.offset().top ) { 
  //       $(this).addClass("animate__animated");
  //       $(this).addClass("animate__pulse");
  //       $(this).addClass("animate__slower");
  //       $(this).addClass("animate__infinite");
  //     }else{
  //       $(this).removeClass("animate__animated");
  //       $(this).removeClass("animate__pulse");
  //       $(this).removeClass("animate__slower");
  //       $(this).removeClass("animate__infinite");
  //     }
  //   })

  // });
  
  /* Cookies */
  $(".close-cookies").on('click',function(e) {
      e.preventDefault();
      $(".cookies").addClass("close-cookies");
  });

  if ($(".modal-autoheight").length > 0) {
    $(".modal").on("show.bs.modal", resize);
    $(window).on("resize", resize);
  }

  $('.modal').on('show.bs.modal', function(){
    var margin_vertical = parseInt( $(this).find('.modal-dialog').css('margin-top') ) + parseInt( $(this).find('.modal-dialog').css('margin-bottom') ) || 0;
    var height_header   = parseInt( $(this).find('.modal-header').css('height') ) || 0;
    var height_footer   = parseInt( $(this).find('.modal-footer').css('height') ) || 0;
    var height_body     = ( window.innerHeight - height_header - height_footer - margin_vertical - 10 ) + 'px';
    $(this).find('.modal-body').css('max-height', height_body).css('overflow', 'auto');

    $('.cookies').css('z-index','1');
  });

  $('.btnScroll').on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });

  // header
  $('.navbar-fostrapClick').on('click',function(){
    $('.nav-fostrap').toggleClass('visible');
    $('.nav-overlay').toggleClass('show');
  });

  $('.mobile-closeBtn').on('click',function(){
    $('.nav-fostrap').removeClass('visible');
    $('.nav-overlay').removeClass('show');
  })

  // Mobile arrow-down
  $('.mobile-menuBtn').on('click',function(){
    var serToggle =  $(this).closest('li').find('.dropdown');
    $(".dropdown").not(serToggle).removeClass('active');
    $('.mobile-menuBtn').not($(this)).removeClass('active');

    if($(this).hasClass('active')){
      $(this).removeClass('active');
      serToggle.removeClass('active');
    }else{
      $(this).addClass('active');
      serToggle.addClass('active');
    }
  });

  $('#js-nav-fostrap').on('mouseenter',function(){
    $('.nav-fostrap-menuBg').addClass('active')
  }).on('mouseleave',function(){
    $('.nav-fostrap-menuBg').removeClass('active')
  });
  
  // top
  $(".float-link .link-btn.top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  })
  
  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  var swiper = new Swiper("#thumbs", {
    watchSlidesProgress: true,
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
      },
      1280: {  //当屏幕宽度大于等于1280 
        slidesPerView: 'auto',
      },
    },
  });
  
  var swiper2 = new Swiper("#gallery", {
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    speed: 500,
    autoHeight: true,
    spaceBetween: 0,
    navigation: {
      nextEl: ".swiper--progress .swiper-button-next",
      prevEl: ".swiper--progress .swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });

  var parallaxSliderOptions = {
    speed: 1000,
    effect: 'coverflow',
    parallax: true,
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 35,
      depth: 10,
      stretch: 0,
      modifier: 1,
      slideShadows: true
    },

    on: {
      init: function() {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          $(swiper.slides[i])
            .find('.img-container')
            .attr({
              'data-swiper-parallax': 0.35 * swiper.width,
              'data-swiper-paralalx-opacity': 0.5
            });
        }
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      transitionEnd: function() {
        let swiper = this;
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      resize: function() {
        this.update();
      }
    },
    pagination: {
      el: ".image-slider .swiper-pagination",
      clickable: true,
    },
  };
  var slider = new Swiper('.image-slider', parallaxSliderOptions);

  var equipSwiperTop = new Swiper('.equip-swiper-top .swiper-container', {
    autoplay: {
      delay: 2000,//2秒切换一次
    },
    loop: true,
    navigation: {
      nextEl: ".equip-swiper-top .swiper-button-next",
      prevEl: ".equip-swiper-top .swiper-button-prev",
    },
    breakpoints: { 
      460: {  //当屏幕宽度大于等于460
        slidesPerView: 1,
        spaceBetween: 0,
      },
      700: {  //当屏幕宽度大于等于700
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      1025: {  //当屏幕宽度大于等于1025
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
      }
    }
  });

  var equipSwiperBottom = new Swiper('.equip-swiper-bottom .swiper-container', {
    autoplay: {
      delay: 3000,//3秒切换一次
    },
    loop: true,
    navigation: {
      nextEl: ".equip-swiper-bottom .swiper-button-next",
      prevEl: ".equip-swiper-bottom .swiper-button-prev",
    },
    breakpoints: { 
      460: {  //当屏幕宽度大于等于460
        slidesPerView: 1,
        spaceBetween: 0
      },
      700: {  //当屏幕宽度大于等于700
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      1025: {  //当屏幕宽度大于等于1025
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
      }
    }
  });

});
